import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import _ from './Underscore';

// TableSorter Config
export const CONFIG = {
    sort: { column: "col2", order: "desc" },
    columns: {
        createdAt: { name: "createdAt", filterText: "", defaultSortOrder: "desc" },
        curDeviceStatus: { name: "curDeviceStatus", filterText: "", defaultSortOrder: "desc" },
        deviceID: { name: "deviceID", filterText: "", defaultSortOrder: "desc" }, 
        flapper: { name: "flapper", filterText: "", defaultSortOrder: "desc" },
        hidden: { name: "hidden", filterText: "", defaultSortOrder: "desc" },
        id: { name: "MySQLid", filterText: "", defaultSortOrder: "desc" },
        locationID: { name: "locationID", filterText: "", defaultSortOrder: "desc" },
        locationCode: { name: "locationCode", filterText: "", defaultSortOrder: "desc" },
        locationLabel: { name: "locationLabel", filterText: "", defaultSortOrder: "desc" },
        oCreatedAt: { name: "oCreatedAt", filterText: "", defaultSortOrder: "desc" },
        toilet: { name: "toilet", filterText: "", defaultSortOrder: "desc" },
        toiletFloat: { name: "toiletFloat", filterText: "", defaultSortOrder: "desc" },
        updatedAt: { name: "updatedAt", filterText: "", defaultSortOrder: "desc" }
        /*userID: { name: "userID", filterText: "", defaultSortOrder: "desc" }*/
    }
};

// Inequality function map for the filtering
const operators = {
    "<": function (x, y) { return x < y; },
    "<=": function (x, y) { return x <= y; },
    ">": function (x, y) { return x > y; },
    ">=": function (x, y) { return x >= y; },
    "==": function (x, y) { return x == y; }
};

// TableSorter React Component
export class TableSorter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.initialItems || [],
            sort: this.props.config.sort || { column: "", order: "" },
            columns: this.props.config.columns
        };
    }

    componentDidUpdate(nextProps) {
        // Load new data when the dataSource property changes.
        if (nextProps.dataSource != this.props.dataSource) {
            this.loadData(nextProps.dataSource);
        }
    }

    componentDidMount() {
        this.loadData(this.props.dataSource);
    }

    loadData(dataSource) {
        if (!dataSource) {
            return;
        }

        this.setState({ items: dataSource });

    }

    handleFilterTextChange(column) {
        return function (newValue) {
            var obj = this.state.columns;
            obj[column].filterText = newValue;

            // Since we have already mutated the state, just call forceUpdate().
            // Ideally we'd copy and setState or use an immutable data structure.
            this.forceUpdate();
        }.bind(this);
    }

    columnNames() {
        return Object.keys(this.state.columns);
    }

    sortColumn(column) {
        return function (event) {
            var newSortOrder = (this.state.sort.order == "asc") ? "desc" : "asc";

            if (this.state.sort.column != column) {
                newSortOrder = this.state.columns[column].defaultSortOrder;
            }

            this.setState({ sort: { column: column, order: newSortOrder } });
        }.bind(this);
    }

    sortClass(column) {
        var ascOrDesc = (this.state.sort.order == "asc") ? "headerSortAsc" : "headerSortDesc";
        return (this.state.sort.column == column) ? ascOrDesc : "";
    }

    render() {
        var rows = [];

        var columnNames = this.columnNames();

        var filters = {};

        var operandRegex = /^((?:(?:[<>]=?)|==))\s?([-]?\d+(?:\.\d+)?)$/;

        columnNames.forEach(function (column) {
            var filterText = this.state.columns[column].filterText;
            filters[column] = null;

            if (filterText.length > 0) {
                let operandMatch = operandRegex.exec(filterText);
                if (operandMatch && operandMatch.length == 3) {
                    //filters[column] = Function.apply(null, ["x", "return x " + operandMatch[1] + " " + operandMatch[2]]);
                    filters[column] = function (match) { return function (x) { return operators[match[1]](x, match[2]); }; }(operandMatch);
                } else {
                    filters[column] = function (x) {
                        if(x === null) x = "";
                        return (x.toString().toLowerCase().indexOf(filterText.toLowerCase()) > -1);
                    };
                }
            }
        }, this);

        var filteredItems = _.filter(this.state.items, function (item) {
            return _.every(columnNames, function (c) {
                return (!filters[c] || filters[c](item[c]));
            }, this);
        }, this);

        var sortedItems = _.sortBy(filteredItems, this.state.sort.column);
        if (this.state.sort.order === "desc") sortedItems.reverse();

        var headerExtra = function () {
            return columnNames.map(function (c) {
                return <th className="header-extra">{this.state.columns[c].name}</th>;
            }, this);
        }.bind(this);

        var cell = function (x) {
            return columnNames.map(function (c) {
                return <td style={{border: "1px solid gray", textAlign: "center", paddingLeft: "6px", paddingRight: "6px"}}>{x[c]}</td>;
            }, this);
        }.bind(this);

        sortedItems.forEach(function (item, idx) {
            var headerRepeat = parseInt(this.props.headerRepeat, 10);
            if ((this.props.headerRepeat > 0) &&
                (idx > 0) &&
                (idx % this.props.headerRepeat === 0)) {

                rows.push(
                    <tr>
                        {headerExtra()}
                    </tr>
                )
            }

            rows.push(
                <tr key={item.id} style={{backgroundColor: idx % 2 === 0 ? "" : "white"}}>
                    {cell(item)}
                </tr>
            );
        }.bind(this));

        var filterLink = function (column, value) {
            this.handleFilterTextChange(column)(value);
        }.bind(this);

        var header = columnNames.map(function (c) {
            return <th style={{textAlign: "center"}} onClick={this.sortColumn(c)} className={"header " + this.sortClass(c)}>{this.state.columns[c].name}</th>;
        }, this);

        var filterInputs = columnNames.map(function (c) {
            return <td><input type="text" onChange={e => filterLink(c, e.target.value)} size="10"/></td>;
        }, this);

        return (
            <table cellSpacing="0" className="tablesorter">
                <thead>
                    <tr>
                        {header}
                    </tr>
                    <tr>
                        {filterInputs}
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
        );
    }
}
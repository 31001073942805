import React from 'react';
import './ToolBlock.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';

const toolBlock = (props) => {

    const title = props.title;
    //console.log(`ToolBlock ID: ${title}`);

    const links = getLinks(title);

    return (
        <Card border="dark" style={{width: '18rem'}}>
            <Card.Header as="h3">{title}</Card.Header>
            <Card.Body>
                    {links}
            </Card.Body>
        </Card>
    );
}

function getLinks(title) {
    switch (title) {
        case "Reports":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/pdfReport">PDF Generator</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/fixtureReport">Toilet Fixtures</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/fulLDeviceReport">Full Device Report</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/rawData">Raw Data</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/installCheck">Install Check</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/buildingReport">Building Report</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/alertLookup">Alert Lookup</Button>
                    </Row>
                </div>
            );
        case "Production":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/provision">Provision</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/retireDevices">Retire</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/shippingMode">Shipping Mode</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/buttonMode">Button Mode</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modelAssignment">Model Assignment</Button>
                    </Row>
                </div>
            );
        case "Testing":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/hallSensorTest" >Hall Sensor</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/updateStatusMessage" >Update Status Interval</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/flowTestReport" >Flow Test Report</Button>
                    </Row>
                </div>
            );
        case "Device":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/allDevices">View All</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/deviceMacAddress">Mac Address Report</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modifyDevice">Modify</Button>
                    </Row>
                </div>
            );
        case "User":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/allUsers" >View All</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modifyUser">Modify</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/addUser" >Add New</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/masquerade">Masquerade</Button>
                    </Row>
                </div>
            );
        case "Organization":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/AdvancedSearch" >Advanced Search</Button>
                    </Row>
                </div>
            );
        case "Properties":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/allProperties" >View All</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modifyProperty">Modify</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/addProperty" >Add New</Button>
                    </Row>
                </div>
            );
        case "Location":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/allLocations">View All</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modifyLocation">Modify</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/addLocation">Add New</Button>
                    </Row>
                </div>
            );
        case "Building":
            return (
                <div align="center">
                    <Row>
                        <Button variant="link" href="/tools/allBuildings">View All</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/modifyBuilding">Modify</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/addBuilding">Add New</Button>
                    </Row>
                    <Row>
                        <Button variant="link" href="/tools/changeWaterRate">Change Water Rate</Button>
                    </Row>
                </div>
            );

        default:
            <div align="center">
            </div>
    }
}

export default toolBlock;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import Swal from 'sweetalert2';
import Container from 'react-bootstrap/Container';
import config from '../../../utilities/config.js';
import AdvSch from './AdvancedSeachVars';
import { Table, Row, Col, Dropdown } from 'react-bootstrap';
import LoadingView from '../../LoadingView/LoadingView.js';
import AdvancedSearchBackButton from './AdvancedSearchBackButton';
import {TableSorter, CONFIG} from './TableSorter.js';

var info = null;
var orgname = null;
var locationInfo = null;
var locationLogData = null;

class LocationResultsView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loadedBuilding: false,
            loadedProp: false,
            loadedOrg: false,
            loadedLocs: false,
        };
    }
    componentDidMount() {
        this.getInfo()
    }
    //get information based on filled out fields
    getInfo() {
        info = []
        info.push(
            <tr key={AdvSch.Building.id}>
                <td>{"Building: " + AdvSch.Building.name}</td>
            </tr>);
        //Get Info
        let url = `${config.api}property/${AdvSch.Building.propID}`;
        let token = localStorage.getItem("mlToken");

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${token}` }
            }
        )
            .then((response) => {
                this.setState({
                    loadedProp: true,
                });
                //console.log(response)
                const data = response["data"];
                
                orgname = data.organizationID
                info.push(
                    <tr key={data.id}>
                        <td>{"Property: " + data.label}</td>
                    </tr>);
                url = `${config.api}organization/${orgname}`;
                token = localStorage.getItem("mlToken");

                axios.get(
                    url,
                    {
                        headers: { Authorization: `Bearer ${token}` }
                    }
                )
                    .then((response) => {
                        this.setState({
                            loadedOrg: true,
                        });
                        const data = response["data"];
                        info.push(
                            <tr key={data.id}>
                                <td>{"Organization: " + data.label}</td>
                            </tr>);

                        return info;
                    },
                        (error) => {
                            //console.log(error);
                        }
                    );



            },
                (error) => {
                    //console.log(error);
                }
            );



    }
    getLoc() {
        //Get all Locations
        if (this.state.loadedLocs) {
            return locationInfo;
        }
        else {
            let url = `${config.api}locations/${AdvSch.Locs.id}`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            )
                .then((response) => {
                    ////console.log(response)
                    const data = response["data"][0];
                    //console.log("data",data)
                    locationInfo = <tbody>
                            <tr key={data.id}>
                                <td><strong>Name:</strong>{data.locationLabel}</td>
                            </tr>
                            <tr key={data.id + 1}>
                                <td><strong>Level:</strong>{data.level}</td>
                            </tr>
                            <tr key={data.id + 2}>
                                <td><strong>Device:</strong>{data.deviceLabel}</td>
                            </tr>
                        </tbody>;
                    ////console.log(locationInfo)
                    this.setState({
                        loadedLocs: true,
                    });
                    return locationInfo;
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }

    // Remove all duplicate location log entries that have the same deviceID and toilet as the previous one
    preFilter(arr) {
        let result = [];
        for (let i = 0; i < arr.length; i++) {
            if (i === 0 || arr[i].deviceID !== arr[i - 1].deviceID || arr[i].toilet !== arr[i - 1].toilet) {
                result.push(arr[i]);
            }
        }
        return result;
    }

    getLocationLogs() {
        // Load the location change logs from the server and load them into a table
        if (this.state.loadedLogs) {
            return;
        } else {
            let url = `${config.api}locations/${AdvSch.Locs.id}/logs`;
            let token = localStorage.getItem("mlToken");

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            ).then((response) => {
                const data = response.data.data.items;
                locationLogData = this.preFilter(data);
                //Object.keys(data[0]).forEach(itemKey => (console.log(itemKey + ': ' + data[0][itemKey])))

                this.setState({
                    loadedLogs: true,
                });
            },
                (error) => {
                    console.log(error);
                }
            );
        }
    }

    createTableRow(obj, index) {
        const row = (
            <tr>
                {Object.entries(obj).map(([key, value]) => (
                    <React.Fragment key={index+'-'+key}>
                        <td>{key + ': ' + value}</td>
                    </React.Fragment>
                ))}
            </tr>
        );
        return row;
    }

    render() {
        if (this.state.loadedProp && this.state.loadedOrg) {
            Swal.close();
            this.getLocationLogs();
            //console.log("Info",info);
            return (<Container>
                <AdvancedSearchBackButton />
                <h1>
                    <Table>
                        <tbody>
                            {info}
                        </tbody>
                    </Table>
                </h1>
                <Row>
                    <Col>
                        <h2>Location:</h2>
                        <Table>
                            {this.getLoc()}
                        </Table>
                        <h2>Change Logs:</h2>
                        {(locationLogData) === null ? <></> : <TableSorter dataSource={locationLogData} config={CONFIG} headerRepeat="50"/>
                    }
                    </Col>
                </Row>
            </Container>)
        }
        else {
            return (
                <div>
                    <LoadingView />
                </div>
            );
        }
    }

}
export default withRouter(LocationResultsView);
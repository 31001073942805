import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import config from '../../utilities/config.js';
import Sidebar from '../../components/Sidebar/Sidebar.js';
import axios from 'axios';
import LoadingView from '../LoadingView/LoadingView';
import Swal from 'sweetalert2';

import './DashboardView.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import GallonsUsed from '../../components/Block/DashboardBlocks/GallonsUsed.js';
import GallonsLeaked from '../../components/Block/DashboardBlocks/GallonsLeaked.js';
import AlertsBlock from '../../components/Block/DashboardBlocks/AlertsBlock.js';
import GraphBlock from '../../components/Block/DashboardBlocks/GraphBlock.js';
import ExpandedGraphBlock from '../../components/Block/DashboardBlocks/ExpandedGraphBlock.js';
import RepairBlock from '../../components/Block/DashboardBlocks/RepairBlock.js';
import TopUsingRooms from '../../components/Block/WaterUseBlocks/TopUsingRooms';
import DataBlock from '../../components/Block/DashboardBlocks/DataBlock.js';
import GeneralGraphBlock from '../../components/Block/DashboardBlocks/GeneralGraphBlock.js';
import TempGraphBlock from '../../components/Block/DashboardBlocks/TempGraphBlock.js';
import HumidGraphBlock from '../../components/Block/DashboardBlocks/HumidGraphBlock.js';
import TempHumidityByRoom from '../../components/Block/TempHumidity/TempHumidityByRoom.js';
import PredictionBlocks from '../../components/Block/PredictionBlocks/PredictionBlocks.js';

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            windowWidth: window.innerWidth,
            data: null,
            selectedBuilding: null,
            leakData: null,
            tempData: null,
            tempsummary: null,
            THdata: null,
            THdataKeys: null,
            humData: null,
            humsummary: null,
            degreeScale: 'F',
            buildings: null,
            startDate: null,
            endDate: null,
            widgetData: null,
            totalRepairs: 0,
            leakTotal: 0,
            THalerts: 0, //temp and humidity alert count
            dateRange: null,
            pData: null,
            loaded: false,
            showModal: false
        };
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#EBF2F8";
        this.generateDashboardSummary(config.dateRange);
        window.addEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
        gtag('config', config.gtag, {
            page_title: 'dashboard_view',
        })
        gtag('event', 'page_view')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.setState({ windowWidth: window.innerWidth }));
    }

    generateDashboardSummary(numDays) {
        this.setState({
            dateRange: numDays,
            loaded: false
        });
        localStorage.setItem("mlDateRange", numDays);

        let url = `${config.api}advanced-building-functions/user/${config.userID}`;
        //get all the buildings for user. If user has only one building, get that building (building), 
        //otherwise, get all building information in the summary.

        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response) => {
                let buildings = response.data;

                if (buildings.length == 1) {//User owns one building
                    //console.log("USER OWNS 1 BUILDING")
                    let building = buildings[0];
                    let buildingID = building["id"];
                    localStorage.setItem("mlBuildingID", buildingID);
                    config.selectedBuilding = buildingID;

                    this.getBuildingInformation(buildings, config.startDate, config.endDate);
                    this.getBuildingInformationTempHumid(buildings, config.startDate, config.endDate);
                    this.getPredictionInformation(buildings, config.startDate, config.endDate);

                }
                else if (buildings.length == 0) {//User has no buildings
                    //this shouldn't be the case but possible and we should handle it
                    Swal.fire(
                        { "title": "You have no buildings setup for this account. Click OK to return to the profile page" }
                    ).then((result) => {
                        /* Read more about handling dismissals below */
                        window.location.href = "/profile";

                    });
                }
                else {//User owns mulitple buildings

                    if (config.selectedBuilding == 0) {
                        this.getUserBuildingInformation(buildings, config.startDate, config.endDate);
                        this.getBuildingInformationTempHumid(buildings, config.startDate, config.endDate);
                        this.getPredictionInformation(buildings, config.startDate, config.endDate);
                    }
                    else {
                        this.getBuildingInformation(buildings, config.startDate, config.endDate);
                        this.getBuildingInformationTempHumid(buildings, config.startDate, config.endDate);
                        this.getPredictionInformation(buildings, config.startDate, config.endDate);
                    }
                }
            });
    }

    getBuildingInformation(buildings, startDate, endDate) {
        //console.log("test1");
        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}`;
        console.log("LEAK INFORMATION ALERTS")
        console.log(url);
        let sCall = Date.now();
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )

            .then((response2) => {
                let eCall = Date.now();



                let lData = response2["data"];
                let keys = Object.keys(lData);
                let fKey = keys[0];
                let iData = lData[fKey]["statsByDate"];
                let rData = {};

                let iDataKeys = Object.keys(iData);
                for (let i = 0; i < iDataKeys.length; i++) {
                    let curKey = iDataKeys[i]
                    let curData = iData[curKey];

                    let date = curKey;
                    let gallons = curData["gallonsUsed"];
                    let flushes = curData["flushes"];
                    let gallonsWasted = curData["gallonsWasted"];
                    let leakCost = curData["leakCost"];
                    rData[date] = curData;

                }
                let leakData = {}
                let leakTotal = 0
                leakData[fKey] = lData[fKey]["statsForProblems"];
                leakTotal = Object.keys(leakData[fKey]).length;

                this.setState({
                    data: rData,
                    leakData: leakData,
                    leakTotal: leakTotal,
                    buildings: buildings,
                    selectedBuilding: config.selectedBuilding,
                    startDate: startDate,
                    endDate: endDate
                });
                this.getBuildingRepairs(buildings, startDate, endDate);


            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    getBuildingInformationTempHumid(buildings, startDate, endDate) {
        //console.log("test2");
        let url = `${config.api}metrics/portalStats/building/${config.selectedBuilding}?startDate=${startDate}&endDate=${endDate}&type=humidity`;
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )
            .then((response2) => {
                let lData = response2["data"];
                let keys = Object.keys(lData);
                let fKey = keys[0];
                let iData = lData[fKey]["statsByDate"];
                let TData = [];
                let HData = [];
                // let THdata=[];
                let Idx = 0;

                let THalerts = {}
                let THalertTotal = 0
                THalerts[fKey] = lData[fKey]["statsForProblems"];
                THalertTotal = Object.keys(THalerts[fKey]).length;

                let iDataKeys = Object.keys(iData);
                //console.log(lData);
                //console.log(iData);
                //console.log("keys", iDataKeys);
                for (let i = 0; i < iDataKeys.length; i++) {
                    let curKey = iDataKeys[i];
                    let curData = iData[curKey];
                    for (let x = 0; x < curData.length; x++) {
                        // THdata[Idx]=curData[x];
                        HData[Idx] = curData[x].humidity;
                        TData[Idx++] = curData[x].temperature;
                    }
                }

                this.setState({
                    tempData: TData,
                    humData: HData,
                    THdata: iData,
                    THdataKeys: iDataKeys,
                    THalerts: THalertTotal,
                    buildings: buildings,
                    selectedBuilding: config.selectedBuilding,
                    startDate: startDate,
                    endDate: endDate
                });


            },
                (error) => {
                    //console.log(error);
                }
            );
    }


    async getPredictionInformation(buildings, startDate, endDate){

        var now = new Date();

        let url = `${config.api}portalStats/predictions/user/${config.userID}?startDate=${startDate}&endDate=${endDate}&latestPrediction=1`;
        console.log("PREDICTION URL")
        console.log(url)
        const result = await 
            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${config.token}` }
                }
            ).then((response) => {


                    //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
                    let pData = response["data"];


                    this.setState({
                        pData: pData,
                    });
                    
                },
                (error) => {
                    //console.log(error);
                }
            );       
    }

    getUserBuildingInformation(buildings, startDate, endDate) {

        var now = new Date();

        //console.log(config);
        let url = `${config.api}metrics/portalStats/user/${config.userID}?startDate=${startDate}&endDate=${endDate}`;
        //console.log(url);
        axios.get(
            url,
            {
                headers: { Authorization: `Bearer ${config.token}` }
            }
        )

            .then((response) => {


                //TODO AGGREGATE DATA BETWEEN THE DIFFERENT BUILDINGS AND THE DATES
                let lData = response["data"];
                let keys = Object.keys(lData);
                let rData = {};

                for (let j = 0; j < keys.length; j++) {
                    let fKey = keys[j];
                    let iData = lData[fKey]["statsByDate"];

                    let iDataKeys = Object.keys(iData);
                    for (let i = 0; i < iDataKeys.length; i++) {
                        let curKey = iDataKeys[i]
                        let curData = iData[curKey];

                        let date = curKey;


                        //there are better ways of merging the objects, but this is the way 
                        if (rData[date] == undefined) {
                            rData[date] = curData;
                        }
                        else {
                            rData[date] = this.mergeTwoObjectValues(rData[date], curData, Object.keys(curData));
                        }


                    }
                }

                let leakData = {}
                let leakTotal = 0
                for (let i = 0; i < keys.length; i++) {
                    const curKey = keys[i];
                    leakData[curKey] = lData[curKey]["statsForProblems"];
                    leakTotal += Object.keys(leakData[curKey]).length
                }

                // const cSDate = this.getCalendarDate(startDate);
                // const cEDate = this.getCalendarDate(endDate);

                this.setState({
                    data: rData,
                    leakData: leakData,
                    leakTotal: leakTotal,
                    buildings: buildings,
                    selectedBuilding: config.selectedBuilding,
                    startDate: startDate,
                    endDate: endDate
                });
                config.buildings = buildings;
                this.getBuildingRepairs(buildings, startDate, endDate);

            },
                (error) => {
                    //console.log(error);
                }
            );
    }

    getBuildingRepairs(buildings, startDateString, endDateString) {
        let repairCount = 0
        let startTime = Date.parse(startDateString);
        let endTime = Date.parse(endDateString);

        if (config.selectedBuilding == 0) {
            let repairLogString = ""
            buildings.forEach((building) => {
                repairLogString += building.id + ","
                
             });
             repairLogString = repairLogString.slice(0, -1)
            this.setState({
                totalRepairs: repairCount,
                loaded: true
            })
            //TODO FIX ENDPOINT FOR THIS
            //  let url = `${config.api}repair-logs/buildings/${repairLogString}?startDate=${startDateString}&endDate=${endDateString}`;
            //  var postData = JSON.stringify({
            //     "token": config.token,
            //     "startDate":startDateString,
            //     "endDate": endDateString,
            //    // "buildingIDs": JSON.parse(repairLogString)
            //    "buildingIDs": []
            // });
            // axios.post(
            //     url,
            //     postData,
            //     {
            //         headers: { Authorization: `Bearer ${config.token}` }

            //     }
            // )
            //     .then((response) => {
            //         let data = response.data;
            //         let records = data["records"];
            //         records.forEach((record) => {
            //             const repairDate = record.repairDate;
            //             if (repairDate > startDateString && repairDate < endDateString) {
            //                 repairCount++
            //             }
            //         });
            //         console.log("TOTAL REPAIRS")
            //         console.log(repairCount)
            //         this.setState({
            //             totalRepairs: repairCount,
            //             loaded: true
            //         })
            //         // this.getDeviceStatus(buildings, startTime, endTime);
            //     },
            // (error) => {
            //     //console.log(error);
            // });
        }
        else {
            let url = `${config.api}repair-logs/building/${config.selectedBuilding}?startDate=${startDateString}&endDate=${endDateString}`;
            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${config.token}` }
                }
            )
                .then((response) => {
                    let data = response.data;
                    let records = data["records"];
                    records.forEach((record) => {
                        const repairDate = record.repairDate;
                        if (repairDate > startDateString && repairDate < endDateString) {
                            repairCount++
                        }
                    });
                    this.setState({
                        totalRepairs: repairCount,
                        loaded: true
                    })
                    // this.getDeviceStatus(buildings, startTime, endTime);
                },
                    (error) => {
                        //console.log(error);
                    });
        }
    }

    getDeviceStatus(buildings, startTime, endTime) {
        let totalRoomData = {};

        if (config.selectedBuilding == 0) {
            buildings.forEach((building) => {
                let buildingID = building.id;
                if (buildingID == 21) { buildingID = 14 };
                let url = `${config.api}metrics/portalStats/building-devices/${buildingID}?startTime=${startTime}&endTime=${endTime}`;

                axios.get(
                    url,
                    {
                        headers: { Authorization: `Bearer ${config.token}` }
                    }
                )

                    .then((response) => {
                        let lData = response["data"];
                        let keys = Object.keys(lData);
                        let fKey = keys[0];
                        let rData = lData[fKey];
                        totalRoomData[building.id] = rData;
                        this.setState({
                            totalRoomData: totalRoomData,
                            loaded: true
                        });
                    },
                        (error) => {
                            //console.log(error);
                        }
                    );
            });
        }
        else {
            let buildingID = config.selectedBuilding;
            if (buildingID == 21) { buildingID = 14 };
            let url = `${config.api}metrics/portalStats/building-devices/${buildingID}?startTime=${startTime}&endTime=${endTime}`;

            axios.get(
                url,
                {
                    headers: { Authorization: `Bearer ${config.token}` }
                }
            )

                .then((response) => {
                    let lData = response["data"];
                    let keys = Object.keys(lData);
                    let fKey = keys[0];
                    let rData = lData[fKey];
                    totalRoomData[config.selectedBuilding] = rData;
                    this.setState({
                        totalRoomData: totalRoomData,
                        loaded: true
                    });
                },
                    (error) => {
                        //console.log(error);
                    }
                );
        }
    }

    exportData = (api, token, title) => {
        if (this.state.loaded) {
            Swal.fire({
                'title': 'Exporting File ...',
                'timer': 60000,
                'allowOutsideClick': false,
                onBeforeOpen: () => {
                    Swal.showLoading()
                }
            });

            let totalGallons = 0;
            let totalGallonsLost = 0;

            let data = this.state.data;
            let gallonData = [];
            let wastedData = [];
            let keys = null;

            if (data != null) {

                keys = Object.keys(data).sort();
                gallonData = [];
                wastedData = [];
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    let gallons = parseInt(data[key]["gallonsUsed"]);
                    let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                    gallonData.push(gallons);
                    wastedData.push(gallonsWasted);
                    totalGallons += gallons;
                    totalGallonsLost += gallonsWasted;
                }
            }

            const chartDates = Object.keys(this.state.data).sort();
            const chartGals = [];
            const chartLeakedGals = [];
            for (let i = 0; i < chartDates.length; i++) {
                chartGals.push(this.state.data[chartDates[i]]["gallonsUsed"]);
                chartLeakedGals.push(this.state.data[chartDates[i]]["gallonsWasted"]);
            }

            const requestBody = {
                "reportType": "summary",
                "data": {
                    "building_title": title,
                    "building_subtitle": "",
                    "start_date": this.state.startDate,
                    "end_date": this.state.endDate,
                    "room_name": "",
                    "gallons_used": totalGallons.toLocaleString(),
                    "gallons_leaked": totalGallonsLost.toLocaleString(),
                    "alerts": this.state.leakTotal,
                    "repairs": this.state.totalRepairs,
                    "gallons_used_array": chartGals,
                    "gallons_leaked_array": chartLeakedGals,
                    "chart_dates": chartDates,
                    "MaxTemp": -1000,
                    "MinTemp": 1000,
                    "MaxHum":-1000,
                    "MinHum":1000
                }
            };

            let url = api + `pdf-page-export`;
            let auth = `Bearer ` + token;
            var config = {
                method: 'post',
                url: url,
                headers: {
                    'Authorization': auth,
                    'Content-Type': 'application/json',
                },
                data: requestBody
            };

            axios(config)
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    let data = response["data"];
                    let uploadFile = data["uploadFile"];
                    let url = uploadFile["uploadFile"];
                    Swal.close()
                    Swal.fire({
                        title: 'Report Created',
                        text: 'Clicking the download button will open a new tab or window to download your file.',
                        icon: 'success',
                        showCancelButton: false,
                        confirmButtonColor: '#3578bd',
                        confirmButtonText: 'Download'
                    }).then(() => {
                        window.open(url);
                    })
                });

        }
    };

    //assuming the keys are the same for each , otherwise, you should use the {...obj1 , ...obj2} operator first
    mergeTwoObjectValues(obj1, obj2, keys) {
        let obj3 = {};
        for (let i = 0; i < keys.length; i++) {
            let curKey = keys[i];
            let val = obj1[curKey] + obj2[curKey];
            obj3[curKey] = val;
        }

        return obj3;


    }

    //this doesn't do date conversions properly, but will do the date conversion so that it shows properly
    //on the calendar option for people to choose from
    getCalendarDate(stringDate) {

        let year = parseInt(stringDate.substring(0, 4));
        let month = parseInt(stringDate.substring(5, 7));
        let day = parseInt(stringDate.substring(8, 10));
        let a = new Date();
        a.setMonth(month - 1);
        a.setDate(day);
        a.setFullYear(year);
        a.setHours(0);
        a.setMinutes(0);
        a.setSeconds(0);
        return a;
    }

    handleShow = () => {
        this.setState({
            showModal: true
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false
        });
    }

    settemp(avgTemp, minTemp, maxTemp) {
        this.setState({
            tempsummary: { "average": avgTemp, "max": maxTemp, "min": minTemp }
        });

    }
    sethum(avgHum, minHum, maxHum) {
        this.setState({
            humsummary: { "average": avgHum, "max": maxHum, "min": minHum }
        });
    }

    render() {
        if (this.state.loaded) {
            let sevenDayGallons = 0;
            let sevenDayGallonsUsedCost = 0;
            let sevenDayFlushes = 0;
            let sevenDayGallonsLost = 0;
            let sevenDayGallonsCost = 0;

            let data = this.state.data;
            let leakData = this.state.leakData;
            let gData = null;
            let gallonData = [];
            let wastedData = [];
            let keys = null;

            let TData = null;
            let HData = null;
            let AvgTemp = 0;
            let MaxTemp = -1000;
            let MinTemp = 1000;
            let TempTab = null;
            let TempTabLower = null;
            let AvgHum = 0;
            let MaxHum = -1000;
            let MinHum = 1000;
            let HumTab = null;
            let HumTabLower = null;
            let pData = null;
            
            pData = this.state.pData;


            if (data != null) {
                keys = Object.keys(data).sort();
                gallonData = [];
                wastedData = [];
                for (let i = 0; i < keys.length; i++) {
                    let key = keys[i];
                    let gallons = parseInt(data[key]["gallonsUsed"]);

                    let flushes = data[key]["flushes"];
                    let gallonsWasted = parseInt(data[key]["gallonsWasted"]);
                    let leakCost = Number(parseFloat(data[key]["leakCost"]).toFixed(2));
                    let gallonsUsedCost = Number(parseFloat(data[key]["cost"]).toFixed(2));
                    gallonData.push(gallons);
                    wastedData.push(gallonsWasted);
                    sevenDayGallons += gallons;
                    sevenDayGallonsUsedCost += gallonsUsedCost;
                    sevenDayFlushes += flushes;
                    sevenDayGallonsLost += gallonsWasted;
                    sevenDayGallonsCost += leakCost;
                }
            }

            if (this.state.tempData != null) {
                TData = this.state.tempData;
                HData = this.state.humData;
                AvgTemp = parseFloat(TData[0]);
                AvgHum = parseFloat(HData[0]);
                for (let x = 0; x < TData.length; x++) {
                    if (TData[x] != null) {
                        AvgTemp += parseFloat(TData[x]);
                        AvgTemp /= 2;
                        MaxTemp = TData[x] > MaxTemp ? TData[x] : MaxTemp;
                        MinTemp = TData[x] < MinTemp ? TData[x] : MinTemp;
                    }
                }

                for (let x = 0; x < HData.length; x++) {
                    if (HData[x] != null) {
                        AvgHum += parseFloat(HData[x]);
                        AvgHum /= 2;
                        MaxHum = HData[x] > MaxHum ? HData[x] : MaxHum;
                        MinHum = HData[x] < MinHum ? HData[x] : MinHum;
                    }
                }
            }
            console.log("TEMPERATURE INFO")
            console.log(MaxTemp)
            console.log(MinTemp)
            if (MaxTemp != -1000 && MinTemp != 1000) {
                console.log("EXECUTED TEMP")
                //Upper small block Temp tab
                TempTab = <Tab style={{ height: '90%' }} eventKey="Temp_max/min" title="Temp">
                    <span style={{ height: '45%' }}>
                        <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((MaxTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : ((Math.round((MaxTemp * 100) / 100)) + "°C")} label="Max Temperature" />
                    </span>
                    <hr />
                    <span style={{ height: '45%' }}>
                        <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((MinTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : (((Math.round(MinTemp * 100) / 100)) + "°C")} label="Min Temperature" />
                    </span>
                </Tab>
                //Lower small block Temp tab
                TempTabLower = <Tab style={{ height: '90%' }} eventKey="Temp_Alerts/Avg" title="Temp">
                    <span style={{ height: '45%' }}>
                        <DataBlock data={this.state.THalerts} label="Alerts" />
                    </span>
                    <hr />
                    <span style={{ height: '45%' }}>
                        <DataBlock data={this.state.degreeScale == 'F' ? (Math.round(((AvgTemp * 9 / 5 + 32) * 100) / 100)) + "°F" : ((Math.round(AvgTemp * 100) / 100) + "°C")} label="Average Temperature" />
                    </span>
                </Tab>
            }

            if (MaxHum != -1000 && MinHum != 1000) {
                //Upper small block Humidity tab
                HumTab = <Tab style={{ height: '90%' }} eventKey="Hum_max/min" title="Humidity">
                    <span style={{ height: '45%' }}>
                        <DataBlock data={MaxHum + "%"} label="Max Humidity" />
                    </span>
                    <hr />
                    <span style={{ height: '45%' }}>
                        <DataBlock data={MinHum + "%"} label="Min Humidity" />
                    </span>
                </Tab>
                //Lower small block Humidity tab
                HumTabLower = <Tab style={{ height: '90%' }} eventKey="Hum_Alerts/Avg" title="Humidity">
                    <span style={{ height: '45%' }}>
                        <DataBlock data={this.state.THalerts} label="Alerts" />
                    </span>
                    <hr />
                    <span style={{ height: '45%' }}>
                        <DataBlock data={(Math.round((AvgHum * 100) / 100)) + "%"} label="Average Humidity" />
                    </span>
                </Tab>
            }
            Swal.close()
            // //console.log('=============================');
            // //console.log(gallonData);
            // //console.log(wastedData);
            console.log("INFO BEFORE GRAPH RENDER")
            console.log(MaxTemp)
            console.log(MaxHum)

            let TempGraph = null
            let HumidityGraph = null


           if(MaxTemp != -1000){
            TempGraph = (<Tab eventKey="Temp_graph" title="Temp" style={{ height: '90%' }}>
                    <span style={{ height: '100%' }}>
                        <TempGraphBlock keys={this.state.THdataKeys} thData={this.state.THdata} label="Temperature" yaxis="temperature" />
                    </span>
                </Tab>)
           }
           if(MaxHum != -1000){
                HumidityGraph = (<Tab eventKey="Hum_graph" title="Humidity" style={{ height: '90%' }}>
                    <span style={{ height: '100%' }}>
                        <HumidGraphBlock keys={this.state.THdataKeys} thData={this.state.THdata} label="Humidity" yaxis="humidity" />
                    </span>
                </Tab>)
           }



            return (
                <div id="pageOuterContainer" className="pageOuterContainer" >
                    {this.state.windowWidth > 650 &&
                        <Sidebar
                            pageWrapId={"pageWrap"}
                            outerContainerId={"pageOuterContainer"}
                            generateSummary={this.generateDashboardSummary.bind(this)}
                            buildings={this.state.buildings}
                            buttonsVisible={true}
                            handleExport={this.exportData}
                        />
                    }
                    <div id="pageWrap" className="pageWrap" >

                        {this.state.windowWidth <= 650 &&
                            <Sidebar
                                pageWrapId={"pageWrap"}
                                outerContainerId={"pageOuterContainer"}
                                generateSummary={this.generateDashboardSummary.bind(this)}
                                buildings={this.state.buildings}
                                buttonsVisible={true}
                                handleExport={this.exportData}
                            />
                        }
                        <Row xs={1} sm={1} className="dashboard-row">
                            <Col md={8} className="dashboardBlock">
                                <div className='BlockBackground'>
                                    <Tabs
                                        defaultActiveKey="gallons_graph"
                                        style={{ fontSize: 'small' }}
                                    >

                                        <Tab eventKey="gallons_graph" title="Water" style={{ height: '90%' }}>
                                            <span style={{ height: '100%' }}>
                                                <GraphBlock keys={keys} gallonData={gallonData} wastedData={wastedData} showModal={this.handleShow} history={this.props.history} />
                                            </span>
                                        </Tab>

                                       {TempGraph}

                                       {HumidityGraph}
                                    </Tabs>
                                </div>
                            </Col>
                            <Col md={4} className="dashboardBlock">
                                <Row style={{ width: '100%' }}>
                                    <Col sm={12} className="testBlock">
                                        <div className='BlockBackground'>
                                            <Tabs
                                                defaultActiveKey="gallonsUsed/leaked"
                                                style={{ fontSize: 'small' }}
                                            >

                                                <Tab style={{ height: '90%' }} eventKey="gallonsUsed/leaked" title="Water">
                                                    <span style={{ height: '45%' }}>
                                                        <GallonsUsed gallonsUsed={sevenDayGallons} history={this.props.history} cost={sevenDayGallonsUsedCost} />
                                                    </span>
                                                    <hr />
                                                    <span style={{ height: '45%' }}>
                                                        <GallonsLeaked gallonsLeaked={sevenDayGallonsLost} history={this.props.history} cost={sevenDayGallonsCost} />
                                                    </span>
                                                </Tab>
{/* 
                                                <Tab style={{ height: '90%' }} eventKey="gallons/dollarsSaved" title="Savings">
                                                    Working in Progress
                                                    <span style={{ height: '45%' }}>

                                                    </span>
                                                    <hr />
                                                    <span style={{ height: '45%' }}>

                                                    </span>
                                                </Tab> */}
                                                {TempTab}
                                                {HumTab}
                                            </Tabs>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row xs={1} sm={1} className="dashboard-row">
                            <Col md={8} className="dashboardBlock">
                                <div className='BlockBackgroundFlex'>
                                    <TopUsingRooms buildings={this.state.buildings} leakData={leakData} />
                                </div>
                            </Col>
                            <Col md={4} className="dashboardBlock" >
                                <Row style={{ width: '100%' }}>
                                    <Col sm={12} className="testBlock">
                                        <div className='BlockBackground'>
                                            <Tabs
                                                defaultActiveKey="Alerts/Repairs"
                                                style={{ fontSize: 'small' }}
                                            >
                                                <Tab style={{ height: '90%' }} eventKey="Alerts/Repairs" title="Water">
                                                    <span style={{ height: '45%' }}>
                                                        <AlertsBlock alerts={this.state.leakTotal} history={this.props.history} />
                                                    </span>
                                                    <hr />
                                                    <span style={{ height: '45%' }}>
                                                        <RepairBlock repairs={`${this.state.totalRepairs}`} history={this.props.history} />
                                                    </span>
                                                </Tab>
                                                {TempTabLower}
                                                {HumTabLower}
                                                
                                            </Tabs>
                                        </div>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row xs={1} sm={1} className="dashboard-row">
                            <Col md={12} className="dashboardBlock">
                                <div className='BlockBackgroundFlex'>
                                    <PredictionBlocks data={pData}/>
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            show={this.state.showModal}
                            onHide={this.handleClose.bind(this)}
                            style={{ opacity: 1 }}
                            dialogClassName="modal-100w"
                            animation={false}
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Water Use</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <ExpandedGraphBlock keys={keys} gallonData={gallonData} wastedData={wastedData} showModal={this.handleShow} history={this.props.history} />
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={this.handleClose.bind(this)}>
                                    Close
                                </Button>
                            </Modal.Footer>
                        </Modal>
                    </div>

                </div>
            );
        }
        else {
            return (
                <LoadingView />
            );
        }

    }
}

export default withRouter(Dashboard);
var config = {
    "old_api": "https://0ptgsin5ie.execute-api.us-east-1.amazonaws.com/alpha/",
   // "api": "http://127.0.0.1:5000/api/",
    "api": "https://api.flowactive.com/api/",
   // "api": "https://api-2023-06-17.flowactive.com/api/",
    "token": "",
    "dateRange": 6,
    "startDate": "",
    "endDate": "",
    "selectedBuilding": 0,
    "isAdmin": 0,
    "isMasquerading": false,
    "userID": 0,
    "fName": "First",
    "lName": "Last",
    "buildings": [],
    "orgID": null,
    "production": true,
    "isActive": 0,
    "DeviceID": 0,
    "gtag":'G-F5RW3KEG35'
}


export default config;